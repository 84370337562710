import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { Timestamp } from "firebase/firestore";

import { getStorage } from "firebase/storage";
/* import projects json */
import projectsList from "../projects"


const firebaseConfig = {
  apiKey: "AIzaSyAt96hMZbL4qUQ_UWwWWnUvxRH494yu9PI",
  authDomain: "my-portfolio-dd37c.firebaseapp.com",
  projectId: "my-portfolio-dd37c",
  storageBucket: "my-portfolio-dd37c.appspot.com",
  messagingSenderId: "167357801626",
  appId: "1:167357801626:web:756afff9fb238a7bc56b23"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/* services */
//init firestore
export const db = getFirestore(app); 

//init storage
export const storage = getStorage(app);

//init functions
export const functions = getFunctions(app);

// timestamp
export const timestamp = Timestamp;




const importProjects = async () => {
  await updateDoc(doc(db, "user", "projects"), {projectsList})
}

/* execute import  */
// importProjects()
